import authRoutes from 'authRoutes'
import React from 'react'
import { useLocation, Route, Switch } from "react-router-dom";

const Auth = () => {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return (
                <Route path={prop.path} key={key} component={prop.component} exact={prop.noExact} />
            );
        });
      };
  return (
      <div className="auth_wrapper">
            <Switch>{getRoutes(authRoutes)}</Switch>
      </div>
  )
}

export default Auth