import React, { useEffect } from 'react'

const ErrorRoute = () => {
    useEffect(() => {
        window.location.replace('/')
    }, [])
  return (
    null
  )
}

export default ErrorRoute