import React, {useState} from 'react'
import {Button, Card, Form, Container, Row, Col} from "react-bootstrap";
import { login } from 'api';
import LoadingModal from 'components/loadingModal/LoadingModal';
import { Link } from 'react-router-dom';
import { toastAlert } from 'components/toastAlert/toastAlert';
import checkErr from 'components/errorHandling/api_error_func';

const Login = () => {
    const [loginData, setLoginData] = useState({
        email: 'mahmoudgawish11@gmail.com',
        password: 'password',
    })
    const [loadingModalShow, setLoadingModalShow] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingModalShow(true)
        if ( !loginData.email) {
            setLoadingModalShow(false)
            return toastAlert("Please Enter Your Email", undefined, "warning");
        }
        if ( !loginData.password) {
            setLoadingModalShow(false)
            return toastAlert("Please Enter Your Password", undefined, "warning");
        }
        login(loginData)
        .then(res => {
            localStorage.setItem('sess', res?.data?.access_token);
            toastAlert('You logged in successfully', undefined, "success");
            setLoadingModalShow(false);
            window.location.reload();
        })
        .catch(error => {
            checkErr(error, setLoadingModalShow, toastAlert)
        })
    }
  return (
    <div className='auth_page_wrapper'>
        <div style={{padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src='https://lockandkey-eg.com/storage/logo.png' style={{height: '75px', width: '75px'}} alt='logo'/>
        </div>
        <Container fluid>
            <Row style={{justifyContent:'center'}}>
                <Col md="5">
                    <Card style={{padding:'5px', justifyContent:'center'}}>
                        <Card.Header>
                            <Card.Title as="h4">Please Login First To Continue</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit} style={{display:'flex', flexDirection: 'column', gap: '10px'}}>
                                <Row>
                                    <Col className="pl-1" md="12">
                                        <Form.Group>
                                            <label htmlFor="exampleInputEmail1">
                                              Email
                                            </label>
                                            <Form.Control
                                              value={loginData.email}
                                              onChange={(e) => setLoginData({...loginData, email: e.target.value})}
                                              placeholder="example@example.com"
                                              type="email"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="pl-1" md="12">
                                        <Form.Group>
                                            <label>Password</label>
                                            <Form.Control
                                              value={loginData.password}
                                              onChange={(e) => setLoginData({...loginData, password: e.target.value})}
                                              type="password"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className='pl-1' md='12'>
                                        <Link to='/forget-password'>Forget Password?</Link>
                                    </Col>
                                </Row>
                                <Button
                                    className="btn-fill pull-right"
                                    type="submit"
                                    variant="dark"
                                    style={{alignSelf: 'flex-end'}}
                                >
                                    Login
                                </Button>
                                <div className="clearfix"></div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => setLoadingModalShow(false)}
        />
    </div>
  )
}

export default Login