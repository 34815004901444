// import { t } from 'i18next';
// import { LOGOUT } from '../constants/actionTypes'

export default function checkErr(error, setLoadingModalShow, toastAlert, option, dispatch) {
  if (error?.response?.data?.error) {
    if (setLoadingModalShow) {
      setLoadingModalShow(false)
    }
    if (toastAlert) {
      toastAlert((error?.response?.data?.error), undefined, "warning");
    }
    if (option) {
      option()
    }
  } else if (error?.response?.data?.errors) {
    for (var key in error?.response?.data?.errors) {
      for (let i=0; i < key.length; i++) {
        if (toastAlert) {
          toastAlert((error?.response?.data?.errors[key][i]), undefined, "warning");
        }
      }
    }
    if (setLoadingModalShow) {
      setLoadingModalShow(false)
    }
    if (option) {
      option()
    }
  } else {
    if (toastAlert) {
      toastAlert(("Something went wrong, please try again later"), undefined, "warning");
    }
    if (setLoadingModalShow) {
      setLoadingModalShow(false)
    }
    if (option) {
      option()
    }
  }
}