import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import Login from "views/Login";
import ForgetPass from "views/ForgetPass";
import ResetPass from "views/ResetPass";
import ErrorRoute from "components/errorRoute/ErrorRoute";

const authRoutes = [
  {
    path: "/",
    component: Login,
    noExact: true,
  },
  {
    path: "/forget-password",
    component: ForgetPass,
    noExact: true,
  },
  {
    path: "/reset-password",
    component: ResetPass,
    noExact: true
  },
  {
    path: "*",
    component: ErrorRoute,
    noExact: false,
  }
];

export default authRoutes;
