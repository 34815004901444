import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Container, Row, Col} from "react-bootstrap";
import { resetPass } from 'api';
import LoadingModal from 'components/loadingModal/LoadingModal';
import { Link } from 'react-router-dom';
import { toastAlert } from 'components/toastAlert/toastAlert';
import { useHistory } from 'react-router-dom'
import {useLocation} from "react-router-dom";
import checkErr from 'components/errorHandling/api_error_func';
const ResetPass = () => {
    const history = useHistory()
    const search = useLocation().search;
    const tokenFromUrl = new URLSearchParams(search).get('t');
    const emailFromUrl = new URLSearchParams(search).get('email');
    const [loginData, setLoginData] = useState({
        token: tokenFromUrl || '',
        email: emailFromUrl || '',
        password_confirmation: '',
        password: '',
    })
    useEffect(() => {
        if (!tokenFromUrl || !emailFromUrl) {
            history.push('/')
        } else {
            setLoginData({...loginData, token: tokenFromUrl, email: emailFromUrl})
        }
    }, [tokenFromUrl, emailFromUrl])
    const [loadingModalShow, setLoadingModalShow] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingModalShow(true)
        if (!loginData.password) {
            setLoadingModalShow(false)
            return toastAlert("Please Enter Your Password", undefined, "warning");
        }
        if (!loginData.password_confirmation) {
            setLoadingModalShow(false)
            return toastAlert("Please Enter Your Confirmation Password", undefined, "warning");
        }
        if (loginData.password !== loginData.password_confirmation) {
            setLoadingModalShow(false)
            return toastAlert("The Two Passwords Don't Match", undefined, "warning");
        }
        resetPass(loginData)
        .then(res => {
            toastAlert(res?.data?.message, undefined, "success");
            setLoadingModalShow(false);
            history.push('/')
        })
        .catch(error => {
            checkErr(error, setLoadingModalShow, toastAlert)
        })
    }
  return (
    <div className='auth_page_wrapper'>
        <div style={{padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src='https://lockandkey-eg.com/storage/logo.png' style={{height: '75px', width: '75px'}} alt='logo'/>
        </div>
        <Container fluid>
            <Row style={{justifyContent:'center'}}>
                <Col md="5">
                    <Card style={{padding:'5px', justifyContent:'center'}}>
                        <Card.Header>
                            <Card.Title as="h4">Please Enter Your Password</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit} style={{display:'flex', flexDirection: 'column', gap: '10px'}}>
                                <Row>
                                    <Col className="pl-1" md="12">
                                        <Form.Group>
                                            <label>Password</label>
                                            <Form.Control
                                              value={loginData.password}
                                              onChange={(e) => setLoginData({...loginData, password: e.target.value})}
                                              type="password"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="pl-1" md="12">
                                        <Form.Group>
                                            <label>Confirm Password</label>
                                            <Form.Control
                                              value={loginData.password_confirmation}
                                              onChange={(e) => setLoginData({...loginData, password_confirmation: e.target.value})}
                                              type="password"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button
                                    className="btn-fill pull-right"
                                    type="submit"
                                    variant="dark"
                                    style={{alignSelf: 'flex-end'}}
                                >
                                    Reset
                                </Button>
                                <Link style={{alignSelf: 'center'}} to='/'>Back</Link>
                                <div className="clearfix"></div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => setLoadingModalShow(false)}
        />
    </div>
  )
}

export default ResetPass