import axios from "axios";
import { baseUrl, baseUrl_admin } from "./baseUrl";

export const login = async (userData) => {
    let finalFormData = new FormData();
    finalFormData.append("email", userData.email);
    finalFormData.append("password", userData.password);
    return await axios({
      method: "post",
      url: `${baseUrl_admin}login`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      mode: "no-cors",
      data: finalFormData,
    });
};

export const forgetPass = async (userData) => {
    let finalFormData = new FormData();
    finalFormData.append("email", userData.email);
    return await axios({
      method: "post",
      url: `${baseUrl_admin}forgot-password`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      mode: "no-cors",
      data: finalFormData,
    });
};

export const resetPass = async (userData) => {
    let finalFormData = new FormData();
    finalFormData.append("token", userData.token);
    finalFormData.append("email", userData.email);
    finalFormData.append("password", userData.password);
    return await axios({
      method: "post",
      url: `${baseUrl_admin}reset-password`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      mode: "no-cors",
      data: finalFormData,
    });
};

