
import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import AdminLayout from "layouts/Admin.js";
import Auth from "layouts/Auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
const token = localStorage.getItem('sess');
console.log(token)
root.render(
  <BrowserRouter>
    <Switch>
      {token?
        <AdminLayout/>:
        <Auth/>
      }
    </Switch>
  </BrowserRouter>
);
