import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import ErrorRoute from "components/errorRoute/ErrorRoute";

const dashboardRoutes = [
  {
    path: "/",
    name: "Home Page",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    noExact: true
  },
  {
    path: "/user",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    noExact: true

  },
  {
    path: "/table",
    name: "Table List",
    icon: "nc-icon nc-notes",
    component: TableList,
    noExact: true

  },
  {
    path: "/typography",
    name: "Typography",
    icon: "nc-icon nc-paper-2",
    component: Typography,
    noExact: true

  },
  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-atom",
    component: Icons,
    noExact: true

  },
  {
    path: "/maps",
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    component: Maps,
    noExact: true

  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    noExact: true

  },
  {
    path: "*",
    name: "Handling",
    icon: "nc-icon nc-bell-55",
    component: ErrorRoute,
    noExact: false,
    sub: true
  }
];

export default dashboardRoutes;
