import React from "react";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";

function LoadingModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popUpLoading"
    >
      <Modal.Body>
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center', gap:'10px'}}>
        <Spinner />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default LoadingModal;
